import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import commonApi from '../utiles/api';

export const CommonContext = createContext();

export function CommonContextProvider({ children }) {
    const navigate = useNavigate();

    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const handleLogout = () => {
        localStorage.removeItem('userIOV');
        localStorage.removeItem('iovToken');
        navigate('/login')
    };
    const secretKey = 'app-iov';
    const storedUserData = localStorage.getItem('userIOV');
    const configData = localStorage.getItem('iovConfig');

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [cityDropDown, setCityDropDown] = useState('');
    const [stateDropDown, setStateDropDown] = useState('');
    const [configVar, setConfigVar] = useState('');
    const [nbCategory, setNbCategory] = useState([]);
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [settingOptions, setSettingOptions] = useState({})
    const [formTemp, setFormTemp] = useState({
        title: '',
        link: '',
        status: '1',
        sort_order: '',
        file: null,
    });
    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onCityDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('city/dropdown');
            if (response.data.s === 1) {
                setCityDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onStateDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('state/dropdown');
            if (response.data.s === 1) {
                setStateDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onNbCategory = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('category/dropdown');
            if (response.data.s === 1) {
                setNbCategory(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onSiteSetting = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('site-settings/get');
            if (response.data.s === 1) {
                setSettingOptions(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (configData) {
            setConfigVar(JSON.parse(configData));
        }
    }, [configData]);
    useEffect(() => {
        if (storedUserData) {
            const parsedUser = decryptData(storedUserData, secretKey);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            onCityDropDown();
            onStateDropDown();
            onSiteSetting();
            onNbCategory();
        }
        if (!storedUserData) {
            navigate('/login');
        }
    }, [storedUserData]);
    const renderFilePreview = (fileUrl, width = '100px') => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        const isPdf = fileExtension === 'pdf';

        if (isPdf) {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        width={width}
                        height={width}
                    />
                </a>
            );
        } else {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        src={fileUrl}
                        alt="Selected File"
                        style={{ maxWidth: '100%', width }}
                    />
                </a>
            );
        }
    };
    const contextValue = {
        configVar,
        onSiteSetting,
        settingOptions,
        setSettingOptions,
        renderFilePreview,
        nbCategory,
        setNbCategory,
        formTemp,
        setFormTemp,
        cityDropDown,
        setCityDropDown,
        stateDropDown,
        setStateDropDown,
        isHovered,
        setIsHovered,
        isSidebarOpen,
        setIsSidebarOpen,
        handleLogout,
        isSearch,
        globalSearch,
        user,
        userPermission,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setIsSearch
    };

    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}

import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const ArticleContext = createContext();

export function ArticleContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState({
        title: '',
        status: '1',
        image: null,
        category_id: '',
        short_desc: '',
        long_desc: '',
    });

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({
            title: '',
            status: '1',
            image: null,
            category_id: '',
            short_desc: '',
            long_desc: '',
        });
    };

    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('articles/list');
            if (response.data.s === 1) {
                setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        onLoadApi();
    }, []);

    const contextValue = {
        onLoadApi,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch
    };

    return <ArticleContext.Provider value={contextValue}>{children}</ArticleContext.Provider>;
}

import React, { useState, useContext, useEffect } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Alert,
    Container,
    Card,
    CardHeader,
    CardBody,
} from 'reactstrap';
import { CommonContext } from '../../context';
import commonApi from '../../utiles/api';
import SuccessMessage from './SuccessMessage';

const SiteSetting = () => {
    const { handleLogout, formTemp, setFormTemp, isHovered, isSidebarOpen, renderFilePreview, settingOptions, onSiteSetting } = useContext(CommonContext);
    const [fileErrors, setFileErrors] = useState({
        logo: '',
        favicon: '',
    });
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    console.log('submitSuccess', submitSuccess)
    useEffect(() => {
        if (settingOptions?.data) {
            const newFormTemp = settingOptions.data.reduce((acc, curr) => {
                acc[curr?.option_key] = curr?.option_value;
                return acc;
            }, {});
            setFormTemp(newFormTemp);
        }
    }, [settingOptions]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'logo' || name === 'favicon') {
            const selectedFile = files[0];
            if (selectedFile && selectedFile.size > 250 * 1024) { // Max 250KB
                setFileErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: 'File size must be less than 250KB',
                }));
                setFormTemp({ ...formTemp, [name]: null });
            } else {
                setFileErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: '',
                }));
                setFormTemp({ ...formTemp, [name]: selectedFile });
            }
        } else {
            setFormTemp({ ...formTemp, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = 'site-settings/update';
        setLoading(true);
        setSubmitError('');
        setSubmitSuccess('');
        const formData = new FormData();
        formData.append('id', formTemp.id ?? '');
        formData.append('company_name', formTemp.company_name ?? '');
        formData.append('site_title', formTemp.site_title ?? '');
        formData.append('site_keywords', formTemp.site_keywords ?? '');
        formData.append('site_desc', formTemp.site_desc ?? '');
        formData.append('ga_code', formTemp.ga_code ?? '');
        formData.append('contact_address', formTemp.contact_address ?? '');
        formData.append('contact_email', formTemp.contact_email ?? '');
        formData.append('contact_phone', formTemp.contact_phone ?? '');
        formData.append('contact_landline', formTemp.contact_landline ?? '');
        formData.append('fb_link', formTemp.fb_link ?? '');
        formData.append('x_link', formTemp.x_link ?? '');
        formData.append('linkedin_link', formTemp.linkedin_link ?? '');
        formData.append('logo', formTemp.logo ?? '');
        formData.append('favicon', formTemp.favicon ?? '');

        try {
            const response = await commonApi.post(url, formData);
            if (response.data.s === 1) {
                setSubmitSuccess(response.data?.msg);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                setTimeout(() => {
                    setSubmitSuccess('');
                    onSiteSetting();
                }, 5000);
            } else {
                setSubmitError(response.data.msg || 'Failed to add data');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                setSubmitError(error?.response?.data?.msg);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`module-main ${isSidebarOpen || isHovered ? '' : 'collapsed'}`}>
            <Container className='module-box site-settings'>
                <Card className='my-4 users-list-wrapper'>
                    <CardHeader>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3 className='page-title'>Site Settings</h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {submitSuccess && <SuccessMessage message={submitSuccess} close={() => setSubmitSuccess('')} />}
                        <Form onSubmit={handleSubmit} className="custom-form">
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="company_name">Company Name</Label>
                                    <Input
                                        type="text"
                                        name="company_name"
                                        id="company_name"
                                        value={formTemp.company_name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="site_title">Site Title</Label>
                                    <Input
                                        type="text"
                                        name="site_title"
                                        id="site_title"
                                        value={formTemp.site_title}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="site_keywords">Site Keywords</Label>
                                    <Input
                                        type="textarea"
                                        name="site_keywords"
                                        id="site_keywords"
                                        value={formTemp.site_keywords}
                                        onChange={handleChange}
                                        rows={2}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="site_desc">Site Description</Label>
                                    <Input
                                        type="textarea"
                                        name="site_desc"
                                        id="site_desc"
                                        value={formTemp.site_desc}
                                        onChange={handleChange}
                                        rows={2}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="ga_code">GA Code</Label>
                                    <Input
                                        type="text"
                                        name="ga_code"
                                        id="ga_code"
                                        value={formTemp.ga_code}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="contact_address">Contact Address</Label>
                                    <Input
                                        type="text"
                                        name="contact_address"
                                        id="contact_address"
                                        value={formTemp.contact_address}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="contact_email">Contact Email</Label>
                                    <Input
                                        type="email"
                                        name="contact_email"
                                        id="contact_email"
                                        value={formTemp.contact_email}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="contact_phone">Contact Phone</Label>
                                    <Input
                                        type="text"
                                        name="contact_phone"
                                        id="contact_phone"
                                        value={formTemp.contact_phone}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="contact_landline">Contact Landline</Label>
                                    <Input
                                        type="text"
                                        name="contact_landline"
                                        id="contact_landline"
                                        value={formTemp.contact_landline}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="fb_link">Facebook Link</Label>
                                    <Input
                                        type="url"
                                        name="fb_link"
                                        id="fb_link"
                                        value={formTemp.fb_link}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="x_link">Twitter/X Link</Label>
                                    <Input
                                        type="url"
                                        name="x_link"
                                        id="x_link"
                                        value={formTemp.x_link}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="linkedin_link">LinkedIn Link</Label>
                                    <Input
                                        type="url"
                                        name="linkedin_link"
                                        id="linkedin_link"
                                        value={formTemp.linkedin_link}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-row">
                                <FormGroup className="col-md-6">
                                    <Label for="logo">Upload Logo (max 250KB)</Label>
                                    <Input
                                        type="file"
                                        name="logo"
                                        id="logo"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                    {fileErrors.logo && <Alert color="danger">{fileErrors.logo}</Alert>}
                                    {formTemp?.logo && (
                                        <div style={{ marginTop: '10px' }}>
                                            {formTemp.logo?.type?.startsWith('image/') ? (
                                                <img
                                                    src={URL.createObjectURL(formTemp.logo)}
                                                    alt="Banner"
                                                    style={{ maxWidth: '100%', width: '100px' }}
                                                />
                                            ) : (
                                                renderFilePreview(`${settingOptions.path}/${formTemp.logo}`)
                                            )}
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label for="favicon">Upload Favicon (max 250KB)</Label>
                                    <Input
                                        type="file"
                                        name="favicon"
                                        id="favicon"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                    {fileErrors.favicon && <Alert color="danger">{fileErrors.favicon}</Alert>}
                                    {formTemp?.favicon && (
                                        <div style={{ marginTop: '10px' }}>
                                            {formTemp.favicon?.type?.startsWith('image/') ? (
                                                <img
                                                    src={URL.createObjectURL(formTemp.favicon)}
                                                    alt="Banner"
                                                    style={{ maxWidth: '100%', width: '100px' }}
                                                />
                                            ) : (
                                                renderFilePreview(`${settingOptions.path}/${formTemp.favicon}`)
                                            )}
                                        </div>
                                    )}
                                </FormGroup>
                            </div>
                            {submitError && <Alert color="danger">{submitError}</Alert>}
                            <div className="text-end">
                                <Button type="submit" color="primary" disabled={loading}>
                                    {loading ? ('Updating...') : ('Update')}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default SiteSetting;

import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const CityContext = createContext();

export function CityContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState({});

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({});
    };

    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('city/list');
            if (response.data.s === 1) {
                setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        onLoadApi();
    }, []);

    const contextValue = {
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onLoadApi
    };

    return <CityContext.Provider value={contextValue}>{children}</CityContext.Provider>;
}

import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';
import { useLocation } from 'react-router-dom';

export const MembersContext = createContext();

export function MembersContextProvider({ children }) {
    const memberTId = localStorage.getItem('memberTempId');
    const { handleLogout } = useContext(CommonContext);
    const location = useLocation();
    const [memberTempId, setMemberTempId] = useState('');
    const [addModal, setAddModal] = useState(false);
    const [exprienceModal, setExprienceModal] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [desListData, setDesListData] = useState([]);
    const [mCategoryDrop, setMCategoryDrop] = useState([]);
    const [mClassDrop, setMClassDrop] = useState([]);
    const [assetsClassDrop, setAssetsClassDrop] = useState([]);
    const [aAssetsClassDrop, setAAssetsClassDrop] = useState([]);
    const [universityDrop, setUniversityDrop] = useState([]);
    const [desDropData, setDesDropData] = useState([]);
    const [mCategoryData, setMCategoryData] = useState([]);
    const [mClassData, setMClassData] = useState([]);
    const [memberListData, setMemberListData] = useState([]);
    const [experienceListData, setExperienceListData] = useState([]);
    const [remarksListData, setRemarksListData] = useState([]);
    const [individualListData, setIndividualListData] = useState([]);
    const [qualificationData, setQualificationData] = useState([]);
    const [locationData, setLocationData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState();

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const exprienceAddModals = () => {
        setExprienceModal(true);
        setIsEdit(false);
    };

    const exprienceCloseModal = () => {
        setExprienceModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const remarksAddModals = () => {
        setRemarksModal(true);
        setIsEdit(false);
    };

    const remarksCloseModal = () => {
        setRemarksModal(false);
        setIsEdit(false);
        setFormTemp();
    };

    const onDesLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/list');
            if (response.data.s === 1) {
                setDesListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDesDropApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/dropdown');
            if (response.data.s === 1) {
                setDesDropData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMCategoryDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/category/dropdown');
            if (response.data.s === 1) {
                setMCategoryDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMClassDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/class/dropdown');
            if (response.data.s === 1) {
                setMClassDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onAssetsClassDrop = async (parentId) => {
        const postReq = {}
        if (parentId) {
            postReq.parent_id = parentId
        }
        setIsLoading(true);
        try {
            const response = await commonApi.post('assets-class/dropdown', postReq);
            if (response.data.s === 1) {
                if (parentId) {
                    setAAssetsClassDrop(response.data.data);
                } else {
                    setAssetsClassDrop(response.data.data);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onUniversityDrop = async (parentId) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/qualification/universities');
            if (response.data.s === 1) {
                setUniversityDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMClassApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/class/list');
            if (response.data.s === 1) {
                setMClassData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMCategoryApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/category/list');
            if (response.data.s === 1) {
                setMCategoryData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onQualificationApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/qualification/list', { member_id: memberTempId });
            if (response.data.s === 1) {
                setQualificationData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }
    const onMemberLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/insti-mem/list', { branch_id: location?.state?.id });
            if (response.data.s === 1) {
                setMemberListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onRemarksLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/remarks-list', { member_id: memberTempId });
            if (response.data.s === 1) {
                setRemarksListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onExprienceLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/experience/list', { member_id: memberTempId });
            if (response.data.s === 1) {
                setExperienceListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onIndividualLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/list');
            if (response.data.s === 1) {
                setIndividualListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (memberTId) {
            setMemberTempId(memberTId);
        } else {
            setMemberTempId('');
        }
    }, [memberTId]);
    useEffect(() => {
        if (memberTempId) {
            onRemarksLoadApi();
        }
    }, [memberTempId]);
    useEffect(() => {
        if (location.pathname === '/member-profile' && location.state?.id) {
            setMemberTempId(location.state?.id);
            onMCategoryDrop();
            onMClassDrop();
            onAssetsClassDrop();
        }
        if (location.pathname !== '/member-profile') {
            localStorage.removeItem('memberTempId');
        }
    }, [location]);
    useEffect(() => {
        if (location?.state) {
            setLocationData(location?.state);
        }
    }, [location]);
    useEffect(() => {
        // onDesLoadApi();
    }, []);

    const contextValue = {
        onUniversityDrop,
        universityDrop,
        setUniversityDrop,
        onExprienceLoadApi,
        experienceListData,
        setExperienceListData,
        remarksListData,
        setRemarksListData,
        onRemarksLoadApi,
        onAssetsClassDrop,
        assetsClassDrop,
        aAssetsClassDrop,
        mClassDrop,
        mCategoryDrop,
        memberTempId,
        individualListData,
        setIndividualListData,
        onIndividualLoadApi,
        remarksModal,
        setRemarksModal,
        remarksCloseModal,
        remarksAddModals,
        exprienceCloseModal,
        exprienceAddModals,
        exprienceModal,
        setExprienceModal,
        onQualificationApi,
        onMemberLoadApi,
        mClassData,
        setMClassData,
        onMClassApi,
        locationData,
        setLocationData,
        onMCategoryApi,
        memberListData,
        setMemberListData,
        qualificationData,
        setQualificationData,
        onDesDropApi,
        desDropData,
        onDesLoadApi,
        onMClassApi,
        mCategoryData,
        setMCategoryData,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        desListData,
        setDesListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onDesLoadApi
    };

    return <MembersContext.Provider value={contextValue}>{children}</MembersContext.Provider>;
}

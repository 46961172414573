import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const PincodeContext = createContext();

export function PincodeContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);
    const [addModal, setAddModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [filterInput, setFilterInput] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [uploadData, setUploadData] = useState({
        file: null,
    });
    const [formTemp, setFormTemp] = useState({});

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };
    const handleUploadModals = () => {
        setUploadModal(true);
    };
    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({});
    };
    const handleCloseUploadModal = () => {
        setUploadModal(false);
        setUploadData({
            file: null,
        });

    };

    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('pincode/list');
            if (response.data.s === 1) {
                setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onExport = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('pincode/downloads', filterInput);
            if (response.data.s === 1) {
                // setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        onLoadApi();
    }, []);

    const contextValue = {
        onExport,
        filterInput,
        setFilterInput,
        uploadData,
        setUploadData,
        handleUploadModals,
        handleCloseUploadModal,
        uploadModal,
        setUploadModal,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onLoadApi
    };

    return <PincodeContext.Provider value={contextValue}>{children}</PincodeContext.Provider>;
}

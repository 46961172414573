import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGooglePlusG, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  NavbarBrand
} from "reactstrap";
import CryptoJS from "crypto-js";
import { CommonContext } from "../../context/CommonContext";
import { useNavigate } from "react-router-dom";
import "../../assets/signin.scss";
import commonApi from "../../utiles/api";
import Logo from "../../assets/image/logo.png";
import { faImagePortrait } from "@fortawesome/free-solid-svg-icons";

const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};
const secretKey = "app-iov";

const SignIn = () => {
  const { setUser } = useContext(CommonContext);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!validateEmail(formData.email)) {
      setError("Invalid email format");
      setLoading(false);
      return;
    }

    try {
      const response = await commonApi.post("users/login", formData);
      if (response.data.s === 1) {
        const userData = response?.data?.data;
        const encryptedUserData = encryptData(userData, secretKey);
        localStorage.setItem("userIOV", encryptedUserData);
        localStorage.setItem("iovToken", response?.data?.token);
        localStorage.setItem("iovConfig", JSON.stringify(response?.data?.variables));
        setUser(userData);
        navigate("/");
      } else {
        setError(response.data.message || "Sign in failed");
      }
    } catch (err) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  return (
    //         <Container fluid classNameName="signin-container">
    //             <Row classNameName="align-items-center justify-content-center min-vh-100">
    //                 <div className="container-xxl">
    //   <div className="authentication-wrapper authentication-basic container-p-y">
    //     <div className="authentication-inner py-4">

    //       <div className="card">
    //         <div className="card-body">

    //           <div className="app-brand justify-content-center">
    //             <a href="index.html" className="app-brand-link gap-2">
    //               <span className="app-brand-text demo h3 mb-0 fw-bold"> <img src={Logo} alt="IOV Logo" className="ndm-logo" /></span>

    //             </a>

    //           </div>

    //           <h4 className="mb-2">Welcome to IOV! 👋</h4>
    //           <p className="mb-4">Please sign-in to your account and start the adventure</p>
    //                  <Form onSubmit={handleSubmit} classNameName="custom-form">
    //                                 {error && <Alert color="danger">{error}</Alert>}
    //                                 <FormGroup>
    //                                     <Label for="email">Email</Label>
    //                                     <Input
    //                                         type="email"
    //                                         name="email"
    //                                         id="email"
    //                                         placeholder="Enter your email"
    //                                         value={formData.email}
    //                                         onChange={handleChange}
    //                                         required
    //                                     />
    //                                 </FormGroup>
    //                                 <FormGroup>
    //                                     <Label for="password">Password</Label>
    //                                     <Input
    //                                         type="password"
    //                                         name="password"
    //                                         id="password"
    //                                         placeholder="Enter your password"
    //                                         value={formData.password}
    //                                         onChange={handleChange}
    //                                         required
    //                                     />
    //                                 </FormGroup>
    //                                 <Button color="primary" type="submit" disabled={loading} block>
    //                                     {loading ? 'Signing in...' : 'Sign In'}
    //                                 </Button>
    //                             </Form>
    //           <div className="divider my-4">
    //             <div className="divider-text">or</div>
    //           </div>

    //           <div className="d-flex justify-content-center">
    //             <a href="javascript:;" className="btn btn-icon btn-label-facebook me-3">
    //               <i className="tf-icons bx bxl-facebook"></i>
    //             </a>

    //             <a href="javascript:;" className="btn btn-icon btn-label-google-plus me-3">
    //               <i className="tf-icons bx bxl-google-plus"></i>
    //             </a>

    //             <a href="javascript:;" className="btn btn-icon btn-label-twitter">
    //               <i className="tf-icons bx bxl-twitter"></i>
    //             </a>
    //           </div>
    //         </div>
    //       </div>

    //     </div>
    //   </div>
    // </div>
    //             </Row>
    //         </Container>
    <Container fluid className="signin-container">
      <Row className="align-items-center justify-content-center min-vh-100">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center">
                    <a href="index.html" className="app-brand-link gap-2">
                      <span className="app-brand-text demo h3 mb-0 fw-bold">
                        <img src={Logo} alt="IOV Logo" className="ndm-logo" />
                      </span>
                    </a>
                  </div>
                  <h4 className="mb-2">Welcome to IOV! 👋</h4>
                  <p className="mb-4">
                    Please sign-in to your account and start. 
                  </p>
                  <Form onSubmit={handleSubmit} className="custom-form">
                    {error && <Alert color="danger">{error}</Alert>}
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={loading}
                      block
                    >
                      {loading ? "Signing in..." : "Sign In"}
                    </Button>
                  </Form>
                  <div className="divider my-4">
                    <div className="divider-text">or</div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-facebook me-3"
                    >
                      <FontAwesomeIcon icon={faFacebookF}  />
                    </a>
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-google-plus me-3"
                    >
                      <FontAwesomeIcon icon={faGooglePlusG} />
                    </a>
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-twitter"
                    >
                       <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default SignIn;

import React from 'react';
import { Alert } from 'reactstrap';

const SuccessMessage = ({ message, close }) => {
  return (
    <Alert color="success" toggle={close}>
      {message}
    </Alert>
  );
};

export default SuccessMessage;
